<template lang="pug">
.form.form---block
  .form__fields
    .form__field
      .input-text.input-text---white
        input.input-text__input(type="text" placeholder="Ваше имя")

    .form__field
      .input-text.input-text---white
        input.input-text__input(type="text" placeholder="+7 (_ _ _) _ _ _ - _ _ - _ _")

  .form__bottom
    .form__consent Нажимая кнопку, Вы соглашаетесь с <a href="/politika-konfidenczialnosti/">политикой конфиденциальности данных</a>

    .form__button
      .button.button---white
        .button__text Вызвать агента

</template>

<script>
export default {
  name: 'AppForm'
}
</script>
