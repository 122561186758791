/*global $*/
/*eslint no-undef: "error"*/
export default class Short {
    classButton = 'short--button'

    constructor () {
        let self = this

        $(`.${self.classButton}`).click(function () {
            let block = $(this).data('block')

            $('html,body').stop().animate(
                {
                    scrollTop: $(`#${block}`).offset().top - 20
                },
                500
            )
        })
    }
}
